var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.interfaceVisible
    ? _c(
        "div",
        {
          directives: [{ name: "customDrag", rawName: "v-customDrag" }],
          staticClass: "ipPhoneBody",
          style: {
            top: _vm.interfaceTopCoordinate,
            left: _vm.interfaceLeftCoordinate,
            width: _vm.windowWidth,
            height: _vm.windowHeight,
            zIndex: _vm.interfaceSize === "small" ? 2001 : 2000
          }
        },
        [
          _c("div", { staticClass: "dragOperationArea" }, [
            _c("div", { staticClass: "dialogheader" }, [
              _c("span", {
                staticClass: "iconfont_Me icon-x closeIconStyle iconStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeInterface.apply(null, arguments)
                  }
                }
              }),
              _vm.countriesData.length != 0
                ? _c(
                    "div",
                    { staticClass: "countrySelection" },
                    [
                      _c("div", { staticClass: "nationalFlag" }, [
                        _c("img", {
                          staticStyle: { height: "30px", width: "30px" },
                          attrs: { src: _vm.nationalFlagUrl, alt: "" }
                        })
                      ]),
                      _c(
                        "AddressSelectorForPhone",
                        { on: { selectThisAreaCode: _vm.selectThisAreaCode } },
                        [
                          _c("div", { staticClass: "selectButton" }, [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-a-zu10330 selectIcon"
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "basicOperation" }, [
                _c("span", {
                  staticClass: "iconfont_Me icon-chongzhi-01 iconStyle",
                  style: { "margin-right": "3px" }
                }),
                _c("span", {
                  staticClass: "iconfont_Me icon-history iconStyle"
                })
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }