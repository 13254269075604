<template>
  <div
    v-customDrag
    class="ipPhoneBody"
    v-if="interfaceVisible"
    :style="{
      top: interfaceTopCoordinate,
      left: interfaceLeftCoordinate,
      width: windowWidth,
      height: windowHeight,
      zIndex: interfaceSize === 'small' ? 2001 : 2000,
    }"
  >
    <div class="dragOperationArea">
      <div class="dialogheader">
        <span
          class="iconfont_Me icon-x closeIconStyle iconStyle"
          @click.stop="closeInterface"
        ></span>
        <div class="countrySelection" v-if="countriesData.length != 0">
          <div class="nationalFlag">
            <img
              style="height: 30px;width:30px"
              :src="nationalFlagUrl"
              alt=""
            />
          </div>
          <AddressSelectorForPhone @selectThisAreaCode="selectThisAreaCode">
            <div class="selectButton">
              <span class="iconfont_Me icon-a-zu10330 selectIcon"></span>
            </div>
          </AddressSelectorForPhone>
        </div>
        <div class="basicOperation">
          <span
            class="iconfont_Me icon-chongzhi-01 iconStyle"
            :style="{ 'margin-right': '3px' }"
          ></span>
          <span class="iconfont_Me icon-history iconStyle"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressSelectorForPhone from "../addressSelector/addressSelectorForPhone.vue";
export default {
  name: "IPTelephone",
  components: {
    AddressSelectorForPhone,
  },
  mounted() {
    this.initInterfacePosition();
    // 根据屏幕宽度设置会议窗口宽度
    window.addEventListener("resize", this.resizeListener, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeListener, false);
  },
  data() {
    return {
      // 初始Y坐标
      interfaceTop: 0,
      // 初始X坐标
      interfaceLeft: 0,
      // IM窗口宽度
      IMwidth: 960,
      // 选中国家的信息,默认中国
      country: {
        sort: 86,
        value: 171,
        label: "中国",
      },
    };
  },
  computed: {
    interfaceData() {
      return this.$store.state.ipTelephone.interfaceData;
    },
    interfaceVisible() {
      return this.interfaceData ? true : false;
    },
    // 处理过的国家数据
    countriesData() {
      return this.$store.state.homePage.sortedCountriesData;
    },
    // 被选中的国家
    nationalFlagUrl() {
      return require(`../../../assets/images/newCountrys/country_flag_${
        this.country ? this.country.value : "0"
      }.png`);
    },
    // 窗口尺寸
    interfaceSize() {
      return this.$store.state.ipTelephone.interfaceSize;
    },
    // 窗口当前真正显示的宽度
    windowWidth() {
      let windowWidth = `${this.normalWidth}px`;
      if (this.interfaceSize === "mini") {
        windowWidth = "224px";
      }
      return windowWidth;
    },
    // 窗口当前真正显示的高度
    windowHeight() {
      let windowHeight = `${this.normalHeight}px`;
      if (this.interfaceSize === "mini") {
        windowHeight = "126px";
      }
      return windowHeight;
    },
    // 正常窗口根据屏幕大小应该显示的宽度
    normalWidth() {
      let normalWidth = 728;
      if (this.interfaceSize === "small") {
        normalWidth = 397;
      }
      return normalWidth;
    },
    // 正常窗口根据屏幕大小应该显示的高度
    normalHeight() {
      let normalHeight = 650;
      if (this.interfaceSize === "small") {
        normalHeight = document.documentElement.clientWidth <= 1400 ? 590 : 650;
      }
      return normalHeight;
    },
    // 窗口当前相对于屏幕顶部的距离
    interfaceTopCoordinate() {
      let interfaceTopCoordinate = "";
      if (this.interfaceSize === "mini") {
        interfaceTopCoordinate = "144px";
      } else {
        interfaceTopCoordinate = `${this.interfaceTop}px`;
      }
      return interfaceTopCoordinate;
    },
    // 窗口当前相对于屏幕左侧的距离
    interfaceLeftCoordinate() {
      let interfaceLeftCoordinate = "";
      if (this.interfaceSize === "mini") {
        interfaceLeftCoordinate = `${document.documentElement.clientWidth -
          122 -
          125}px`;
      } else {
        interfaceLeftCoordinate = `${this.interfaceLeft}px`;
      }
      return interfaceLeftCoordinate;
    },
  },
  methods: {
    // 关闭界面
    closeInterface() {
      this.$store.commit("setInterfaceData", null);
    },
    async resizeListener() {
      await this.initInterfacePosition();
    },
    // 初始化定位以及界面尺寸
    async initInterfacePosition() {
      let documentWidth = document.documentElement.clientWidth;
      let documentHeight = document.documentElement.clientHeight;
      if (documentWidth <= 1400) {
        this.interfaceTop = documentHeight - 590;
      } else {
        this.interfaceTop = documentHeight - 650;
      }
      await this.initInterfaceSize(documentWidth);
      let windowWidth = this.windowWidth.substr(0, this.windowWidth.length - 2);
      let remainingWidthOnLeft =
        documentWidth - this.IMwidth - windowWidth - 36;
      if (remainingWidthOnLeft > 0) {
        this.interfaceLeft = remainingWidthOnLeft;
      } else {
        this.interfaceLeft = 0;
      }
    },
    // 根据屏幕大小设置窗口宽高度
    initInterfaceSize(documentWidth) {
      return new Promise((resolve, reject) => {
        let remainingWidth = documentWidth - this.IMwidth;
        if (remainingWidth < 728) {
          this.$store.commit("setIPPhoneInterfaceSize", "small");
        } else {
          this.$store.commit("setIPPhoneInterfaceSize", "normal");
        }
        this.$forceUpdate();
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
    // 选中国家
    selectThisAreaCode(code) {
      this.countriesData.map((data) => {
        data.children.map((item) => {
          if (item.sort == code) {
            this.country = item;
          }
        });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.ipPhoneBody
  position: fixed;
  overflow: hidden;
  user-select: none;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  background: #282828;
  border-radius: 10px;
  z-index: 2000;
  .dragOperationArea
    width: 100%;
    height: 100%;
    position: relative;
    .dialogheader
      width: 100%;
      height: 24px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      .countrySelection
        width: 55px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .selectButton
          color #FFFFFF;
          cursor pointer;
        .nationalFlag
          height: 30px;
          width: 30px;
      .iconStyle
        font-size: 24px;
        color: #CFCFCF;
        cursor: pointer;
      .closeIconStyle
        margin-left: 20px;
      .basicOperation
        height: 100%;
        overflow: hidden;
        margin-right: 20px;
</style>
